<template>
  <div class="productContent">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">终止信贷流程</div>
    <div class="card">
        <div class="catalog">
            <p>企业名称：</p>
            <p>{{approvalDetail.enterName}}</p>         
        </div>
        <div class="catalog">
            <p>申请银行：</p>
            <p>{{approvalDetail.bankName}}</p>         
        </div>
        <div class="catalog">
            <p>审核状态：</p>
            <p class="yellow">{{ replaceLoanStatus(approvalDetail.checkStatus)}}</p>         
        </div>
        <div class="catalog">
            <p>终止原因<span class="key">*</span></p>
            <textarea v-model="termiCause"></textarea>
        </div>
        <div style="text-align: center;padding: 4vw 0;">
            <div class="edit" @click="submit()">提交</div>
        </div>      
    </div>
    
    
  </div>
</template>
<script>
import { Toast } from 'vant';
import { ref,reactive } from 'vue';
import { useRouter } from 'vue-router';
import { apiStopApprovalInit,replaceLoanStatus,apiStopSH } from '../../api/axios';
export default {
   setup() {
    let router = useRouter();
    // console.log(router.currentRoute.value.params.approvalId,999);
    let aId = router.currentRoute.value.params.approvalId;
    const approvalDetail = ref({
        checkStatus: ''
    });
    const termiCause = ref('');
    apiStopApprovalInit({approvalId: aId}).then(res => {                  
             if(res.code == '1'){                
                approvalDetail.value = res.info.approvalDetail;
                // console.log(111)
              }
              else {
                Toast(res.msg);
              }
    });
    const submit = () => {
        if(termiCause.value.trim().length > 0) {
            apiStopSH({approvalId: aId,termiCause: termiCause.value}).then(res => {              
             if(res.code == '1'){                
                Toast.success('提交成功！');
                router.go(-1);
              }
              else {
                Toast(res.msg);
              }
    });
        }else {
            Toast('请先填写终止原因');
        }
    }
    return { 
        approvalDetail,
        replaceLoanStatus,
        termiCause,
        submit
    };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },

  }
}
</script>
<style lang="less" scoped>
.productContent {
    padding-top: 12.5vw;
    .card {
        padding: 2vw 4vw;
        .catalog {
            display: flex;
            align-items: baseline;
            p:first-child {
                width: 26vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
                .key {
                    color: #ff6600;
                }
            }
            .yellow {
                color: #ff6600;
            }
            p:last-child {
                width: 55vw;
                font-size: 4vw;
                // padding-left: 4vw;
            }

            textarea {
                background-color: #f4f8fe;
                border: 0;
                // margin-left: 4vw;
                width: 59vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
                height: 30vw;
                resize: none;
            }

        }
        .edit {
            display: inline-block;
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4.5vw;
            padding: 1vw 8vw;
            border-radius: 4vw;
            border: 0;
        }
        
    }
    
    
}
</style>
